import React, {Component, Fragment} from 'react'
import Breadcrumb from '../common/breadcrumb';
import Datatable from '../common/datatable'
import {getOrders} from "../../services/api";
import Image from "../common/image";

export class Orders extends Component {
    constructor(props) {
        super(props);
        this.state = {
            orders: [],
        }
    }

    componentDidMount() {
        getOrders()
            .then(data => {
                this.setState({
                    orders: this.mapToFrontData(data),
                })
            })
    }

    mapToFrontData(data) {
        const orders = [];

        function generatePrice(item) {
            function getCurrencyFromLocation(location) {
                console.log(location)
                switch (location) {
                    case "ARMENIA":
                        return "֏";
                    case "RUSSIA":
                        return "₽";
                    case "EUROPE":
                        return "€";
                    case "OTHER":
                    default:
                        return "$";
                }
            }

            return "Price: " + (item.subTotal + (getCurrencyFromLocation(item.location))) +
                "\nShipping: " + (item.shippingPrice + (getCurrencyFromLocation(item.location))) +
                "\nTotal: " + (item.subTotal + item.shippingPrice + (getCurrencyFromLocation(item.location)));
        }

        data.forEach(item => {
            orders.push({
                orderId: item.orderId,
                total: generatePrice(item),
                products: <Image data={item.products}/>,
                date: this.formatDate(item.date),
                email: item.clientInfo.email,
                phone: item.clientInfo.phone,
                country: item.clientInfo.country,
                city: item.clientInfo.city,
                address: item.clientInfo.address,
                postalCode: item.clientInfo.postalCode,
                paymentId: item.paymentId,
            })
        });
        return orders;
    }

    formatDate(date) {
        return new Date(date);
    }

    render() {
        const {orders} = this.state;
        return (
            <Fragment>
                <Breadcrumb title="Orders" parent="Sales"/>

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5>Manage Order</h5>
                                </div>
                                <div className="card-body order-datatable">
                                    <Datatable
                                        multiSelectOption={false}
                                        orders={orders}
                                        pageSize={10}
                                        pagination={true}
                                        class="-striped -highlight"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}

export default Orders

import React, {Component, Fragment} from 'react'
import Breadcrumb from '../../common/breadcrumb';
import {Edit} from 'react-feather'
import {getAllProducts} from "../../../services/api";

import {CategoryMap, Locations, SubCategoryMap} from '../../../config';


export class ApartmentList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: []
        }
    }

    componentDidMount() {
        getAllProducts()
            .then(data => {
              data.reverse();
                this.setState({
                    data: data
                });
            });
    }

    render() {
        return (
            <Fragment>
                <Breadcrumb title="Product List" parent="Physical"/>
                <div className="container-fluid">
                    <div className="row products-admin ratio_asos">
                        {
                            this.state.data.map((myData, i) => {
                                return (
                                    <div className="col-xl-2 col-sm-3" key={i}>
                                        <div className="card">
                                            <div className="products-admin">
                                                <div className="card-body product-box">
                                                    <div className="img-wrapper">
                                                        <div className="lable-block">
                                                            {myData.isNew ? <span className="lable3">New</span> : ''}
                                                            {!myData.isEnabled ? <span className="lable4" style={{
                                                                color: 'red',
                                                                backgroundColor: 'white',
                                                                borderRadius: '8%',
                                                                padding: '5px'
                                                            }}>Disabled</span> : ''}
                                                        </div>
                                                        <div className="front">
                                                            <a className="bg-size">
                                                                <img className="img-fluid blur-up bg-img lazyloaded"
                                                                     src={myData.pictures[0]}/></a>
                                                            <div className="product-hover">
                                                                <a href={`/products/add-product?productId=${myData.id}`}>
                                                                    <button className="btn" type="button">
                                                                        <Edit className="editBtn"/>
                                                                    </button>
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="product-detail"
                                                         style={{marginTop: '10px', cursor: 'pointer'}}>
                                                        <h6>{myData.name}</h6>
                                                        <h6>{CategoryMap[myData.category]}</h6>
                                                        {myData.subCategory &&
                                                        <h6>{SubCategoryMap[myData.subCategory]}</h6>}
                                                        {
                                                            myData.isSale ?
                                                                <h4>{myData.saleMap ? myData.saleMap[Locations.OTHER] : '???'}{myData.currencySymbol}
                                                                    <del
                                                                        style={{marginLeft: '5px'}}>{myData.priceMap ? myData.priceMap[Locations.OTHER] : '???'}{myData.currencySymbol}</del>
                                                                </h4>
                                                                :
                                                                <h4>{myData.priceMap ? myData.priceMap[Locations.OTHER] : '???'}{myData.currencySymbol}</h4>
                                                        }
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }

                    </div>
                </div>
            </Fragment>
        )
    }
}

export default ApartmentList

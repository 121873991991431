import React, {Component, Fragment} from 'react'
import Breadcrumb from '../../common/breadcrumb';
import {Edit} from 'react-feather'
import {getBuildingsDetails} from "../../../services/api";


export class FloorList extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: []
        }
    }

    componentDidMount() {
        getBuildingsDetails()
            .then(data => {
                this.setState({
                    data: data
                });
            });
    }

    render() {
        console.log(this.state.data)
        return (
            <Fragment>
                <Breadcrumb title="Floors" parent="Physical"/>
                <div className="container-fluid">
                    <div className="row products-admin ratio_asos">
                        {
                            this.state.data.map((myData, i) => {
                                return (
                                    myData.floors.map((element, j)=>
                                    {
                                        return (
                                            <div className="col-xl-2 col-sm-3" key={i}>
                                                <div className="card">
                                                    <div className="products-admin">
                                                        <div className="card-body product-box">
                                                            <div className="img-wrapper">
                                                                <div className="front">
                                                                    <a href={`/floor/edit?floorId=${element.id}`}>
                                                                        <button className="btn" type="button">
                                                                            <Edit className="editBtn"/>
                                                                        </button>
                                                                    </a>
                                                                </div>
                                                            </div>
                                                            <div className="product-detail"
                                                                 style={{marginTop: '10px', cursor: 'pointer'}}>
                                                                <h6>Building : {myData.buildingName}</h6>
                                                                <h6>Floor number : {element.floorNumber}</h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    }));

                                }
                            )
                        }

                    </div>
                </div>
            </Fragment>
        )
    }
}

export default FloorList

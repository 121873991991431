import {Anchor, Box, DollarSign, Home, MessageCircle, Settings} from 'react-feather';

export const MENUITEMS = [

  {
    title: 'Buildings', icon: Home, type: 'sub', active: false, children: [

      {path: '/buildings', title: 'Buildings', type: 'link'},
      {path: '/floors', title: 'Floors', type: 'link'},
    ]
  },

  {
    title: 'Messages', icon: MessageCircle, path: '/messages', type: 'link', active: false
  }
];

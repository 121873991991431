import React, {Component, Fragment} from 'react';
import Breadcrumb from '../../common/breadcrumb';

import {getAllMessages} from '../../../services/api'

import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


export class Messages extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: [],
        }
    }

    componentDidMount() {
        console.log('aaaaa');
        getAllMessages()
            .then(messages => {
                    this.setState({
                        data: messages.body,
                    })
                }
            )
    }

    render() {
        console.log(this.state.data);
        return (
            <Fragment>
                <Breadcrumb title="All Messages" parent="Physical"/>

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5>All Messages</h5>
                                </div>
                                {
                                    this.state.data.map((message, i) => {
                                        return (
                                            <div className="card-body" style={{border: '1px solid black', padding: '3%', margin: '1%'}}>
                                                <div className="col-xl-7">
                                                    <div className="form-group mb-3 row"  style={{borderBottom: '1px solid black ', borderLeft: '1px solid black '}}>
                                                        <div style={{float: 'left', fontWeight: 'bold'}} className="col-xl-3 col-sm-4 mb-0">Id: </div>
                                                        <div style={{float: 'right'}} className="col-xl-3 col-sm-4 mb-0">{message.id}</div>
                                                    </div>
                                                    <div className="form-group mb-3 row"  style={{borderBottom: '1px solid black ', borderLeft: '1px solid black '}}>
                                                        <div style={{float: 'left', fontWeight: 'bold'}} className="col-xl-3 col-sm-4 mb-0">Name: </div>
                                                        <div style={{float: 'right'}} className="col-xl-3 col-sm-4 mb-0">{message.clientName}</div>
                                                    </div>
                                                    <div className="form-group mb-3 row"  style={{borderBottom: '1px solid black ', borderLeft: '1px solid black '}}>
                                                        <div style={{float: 'left', fontWeight: 'bold'}} className="col-xl-3 col-sm-4 mb-0">Phone Number:</div>
                                                        <div style={{float: 'right'}} className="col-xl-3 col-sm-4 mb-0">{message.phoneNumber}</div>
                                                    </div>
                                                    <div className="form-group mb-3 row" style={{borderBottom: '1px solid black ', borderLeft: '1px solid black '}}>
                                                        <div style={{float: 'left', fontWeight: 'bold'}} className="col-xl-3 col-sm-4 mb-0">Email:</div>
                                                        <div style={{float: 'right'}} className="col-xl-3 col-sm-4 mb-0">{message.email}</div>
                                                    </div>
                                                    <div className="form-group mb-3 row" style={{borderBottom: '1px solid black ', borderLeft: '1px solid black '}}>
                                                        <div style={{float: 'left', fontWeight: 'bold'}} className="col-xl-3 col-sm-4 mb-0">Text:</div>
                                                        <div style={{float: 'right'}} className="col-xl-3 col-sm-4 mb-0">{message.text}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        );
                                    })
                                }

                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer/>
            </Fragment>
        )
    }

}

export default Messages

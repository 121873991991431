import {request, requestWithoutJwt} from '../utils/RequestManager';

import {config} from '../config';

const backendHost = config.BACKEND_HOST;
const fileUploadHost = config.FILE_UPLOAD_HOST;

export function getAllMessages() {
  return request({
    method: 'GET',
    baseURL: backendHost,
    url: `/client`,
  }).then(response => response.data);
}

export function editApartment(item) {
  return request({
    method: 'POST',
    baseURL: backendHost,
    url: `/apartment`,
    data: item,
  }).then(response => response.data);
}

export function getFloorById(id) {
  return request({
    method: 'GET',
    baseURL: backendHost,
    url: `/floors/${id}`,
  }).then(response => response.data);
}

export function getBuildingNameByFloorId(id) {
  return request({
    method: 'GET',
    baseURL: backendHost,
    url: `/floors/${id}/building`,
  }).then(response => response.data);
}

export function getApartmentById(id) {
  return request({
    method: 'GET',
    baseURL: backendHost,
    url: `/apartment/${id}`,
  }).then(response => response.data);
}

export function getBuildingsDetailsById(id) {
  return request({
    method: 'GET',
    baseURL: backendHost,
    url: `/building/details/${id}`,
  }).then(response => response.data);
}
export function getBuildingsDetails() {
  return request({
    method: 'GET',
    baseURL: backendHost,
    url: '/building/details',
  }).then(response => response.data);
}

export function getBuildingInfos() {
  return request({
    method: 'GET',
    baseURL: backendHost,
    url: '/building/info',
  }).then(response => response.data);
}
export function getBuildingInfoById(id) {
  return request({
    method: 'GET',
    baseURL: backendHost,
    url: `/building/info/${id}`,
  }).then(response => response.data);
}

export function getBuildingDetails() {
  return request({
    method: 'GET',
    baseURL: backendHost,
    url: '/building/info',
  }).then(response => response.data);
}

export function getCategories() {
  return request({
    method: 'GET',
    baseURL: backendHost,
    url: '/categories',
  }).then(response => response.data);
}

export function getAllProducts() {
  return request({
    method: 'GET',
    baseURL: backendHost,
    url: '/products?requireDisabled=true',
  }).then(response => response.data);
}

export function getProductById(productId) {
  return request({
    method: 'GET',
    baseURL: backendHost,
    url: `/products?productId=${productId}`,
  }).then(response => response.data);
}

export function saveProduct(product) {
  return request({
    method: 'POST',
    baseURL: backendHost,
    url: '/products',
    data: product,
  }).then(response => response.data);
}

export function getCountries() {
  return request({
    method: 'GET',
    baseURL: backendHost,
    url: '/countries',
  }).then(response => response.data);
}

export function getCountriesNew() {
  return request({
    method: 'GET',
    baseURL: backendHost,
    url: '/countries/new',
  }).then(response => response.data);
}

export function getCountryById(id) {
  return request({
    method: 'GET',
    baseURL: backendHost,
    url: '/countries/' + id,
  }).then(response => response.data);
}

export function saveCountry(country) {
  return request({
    method: 'POST',
    baseURL: backendHost,
    url: '/countries/new',
    data: country,
  }).then(response => response.data);
}

export function saveCountries(countries) {
  return request({
    method: 'POST',
    baseURL: backendHost,
    url: '/countries',
    data: countries,
  }).then(response => response.data);
}

export function uploadFile(formData) {
  return requestWithoutJwt({
    method: 'POST',
    baseURL: fileUploadHost,
    url: '/upload/file',
    data: formData,
  }).then(response => response.data);
}

export function getOrders() {
  return request({
    method: 'GET',
    baseURL: backendHost,
    url: '/orders',
  }).then(response => response.data);
}

export function login(userName, password) {
  return request({
    withCredentials: true,
    method: 'POST',
    url: `${backendHost}/login`,
    data: {
      userName,
      password,
    },
  });
}

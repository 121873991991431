import React, {Component, Fragment} from 'react';
import Breadcrumb from '../../common/breadcrumb';
import {AvField, AvForm} from 'availity-reactstrap-validation';
import user from '../../../assets/images/dashboard/designer.jpg';

import Switch from "react-switch";

import {getBuildingInfoById, saveProduct, uploadFile} from '../../../services/api'

import {toast, ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import {
    CategoryMap,
    CategoryReverseMap,
    KidsSubCategoryReverseMap,
    MenSubCategoryReverseMap,
    SubCategoryMap,
    WomenSubCategoryReverseMap
} from '../../../config';
import {Link} from "react-router-dom";
import * as qs from "query-string";


export class Add_product extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: {},
            id: 1,
            name: '',
            parkingFloors: 0,
            imagePath: '',
            viewBox: '',
            buildings: []
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        const {location: {search}} = nextProps;
        const queries = qs.parse(search, {ignoreQueryPrefix: true});
        const buildingId = queries.buildingId;
        if (buildingId) {
            getBuildingInfoById(buildingId)
                .then(building => {
                        this.setState({
                            data: building,
                            id: building.id,
                            name: building.name,
                            parkingFloors: building.parkingFloors,
                            imagePath: building.imagePath,
                            viewBox: building.viewBox,
                            buildings: building.buildings
                        })
                    }
                )
        }
    }

    componentDidMount() {
        const {location: {search}} = this.props;
        const queries = qs.parse(search, {ignoreQueryPrefix: true});
        const buildingId = queries.buildingId;

        if (buildingId) {
            getBuildingInfoById(buildingId)
                .then(building => {
                        this.setState({
                            data: building,
                            id: building.id,
                            name: building.name,
                            parkingFloors: building.parkingFloors,
                            imagePath: building.imagePath,
                            viewBox: building.viewBox,
                            buildings: building.buildings
                        })
                    }
                )
        }
    }

    handleSaveClick() {
/*
        const priceMap = {
            "ARMENIA": this.state.priceAMD,
            "EUROPE": this.state.priceEUROPE,
            "RUSSIA": this.state.priceRUSSIA,
            "OTHER": this.state.price,
        };
        const saleMap = {
            "ARMENIA": this.state.salePriceAMD,
            "EUROPE": this.state.salePriceEUROPE,
            "RUSSIA": this.state.salePriceRUSSIA,
            "OTHER": this.state.salePrice,
        };
        const product = {
            id: this.state.id,
            name: this.state.productName,
            priceMap: priceMap,
            saleMap: saleMap,
            weightInGrams: this.state.weightInGrams,
            pictures: this.state.images,
            isNew: this.state.isNew,
            isSale: this.state.isSale,
            isEnabled: this.state.isEnabled,
            isDeleted: this.state.isDeleted,
            category: CategoryReverseMap[this.state.selectedCategory],
            subCategory: this.state.selectedSubCategory && this.state.selectedCategory === 'Men' ? MenSubCategoryReverseMap[this.state.selectedSubCategory]
                : this.state.selectedCategory === 'Kids' ? KidsSubCategoryReverseMap[this.state.selectedSubCategory] : WomenSubCategoryReverseMap[this.state.selectedSubCategory],
            sizeAndCountList: this.state.sizeAndCountMap,
            description: this.state.description,
            details: this.state.details
        };

        saveProduct(product)
            .then(data => {
                this.setState({
                    id: data.id
                });
                toast.success("Successfully Saved!");
            })
            .catch((err) => {
                toast.error("Can't Save now!")
            });*/
    }

    changeField = (event, name) => {
        this.setState({
            [name]: event.target.value
        });
    };
    changeBuildingName = (event, index) => {
        const buildings = this.state.buildings;
        buildings[index].name = event.target.value;
        this.setState(
            buildings
        );
    };
    addNewBuilding = () => {
        const buildings = this.state.buildings;
        const building = {
            buildingName: 'new Building'
        };
        buildings.push(building);
        this.setState(
            buildings
        );
    };
    deleteBuilding = (index) => {
        const buildings = this.state.buildings;
        buildings.splice(index, 1);
        this.setState(
            buildings
        );
    };
    changeIsNew = () => {
        this.setState({
            isNew: !this.state.isNew
        });
    };
    changeIsSale = () => {
        this.setState({
            isSale: !this.state.isSale
        });
    };
    changeIsEnabled = () => {
        this.setState({
            isEnabled: !this.state.isEnabled
        });
    };
    changeIsDeleted = () => {
        this.setState({
            isDeleted: !this.state.isDeleted
        });
    };

    deleteImage(index) {
        const {images} = this.state;
        images[index] = null;
        this.setState({
            images
        });
    }

    shiftImage(count, index) {
        const {images} = this.state;
        this.array_move(images, index, index + count);
        this.setState({
            images
        })
    }

    array_move(arr, old_index, new_index) {
        if (new_index >= arr.length) {
            var k = new_index - arr.length + 1;
            while (k--) {
                arr.push(undefined);
            }
        }
        arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
        return arr; // for testing
    };

    render() {
        return (
            <Fragment>
                <Breadcrumb title="Add / Edit Building" parent="Physical"/>

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5>Add / Edit Building</h5>
                                </div>
                                <div className="card-body">
                                    <div className="row product-adding">
                                        <div className="col-xl-7">
                                            <AvForm className="needs-validation add-product-form"
                                                    onValidSubmit={this.handleValidSubmit}
                                                    onInvalidSubmit={this.handleInvalidSubmit}>
                                                <div className="form form-label-center">
                                                    <div>
                                                        <div className="form-group mb-3 row">
                                                            <label className="col-xl-3 col-sm-4 mb-0">Id :</label>
                                                            <div className="col-xl-8 col-sm-7">
                                                                <AvField className="form-control mb-0" name="id"
                                                                         type="text" required value={this.state.id}
                                                                         onChange={(event) => this.changeField(event, 'id')}/>
                                                            </div>
                                                            <div className="valid-feedback">Looks good!</div>
                                                        </div>
                                                        <div className="form-group mb-3 row">
                                                            <label className="col-xl-3 col-sm-4 mb-0">Name :</label>
                                                            <div className="col-xl-8 col-sm-7">
                                                                <AvField className="form-control mb-0" name="name"
                                                                         type="text" required value={this.state.name}
                                                                         onChange={(event) => this.changeField(event, 'name')}/>
                                                            </div>
                                                            <div className="valid-feedback">Looks good!</div>
                                                        </div>
                                                        <div className="form-group mb-3 row">
                                                            <label className="col-xl-3 col-sm-4 mb-0">Parking Floors Count</label>
                                                            <div className="col-xl-8 col-sm-7">
                                                                <AvField className="form-control mb-0" name="parkingFloors"
                                                                         type="number" value={this.state.parkingFloors}
                                                                         onChange={(event) => this.changeField(event, 'parkingFloors')}/>
                                                            </div>
                                                            <div className="valid-feedback">Looks good!</div>
                                                        </div>
                                                        <div className="form-group mb-3 row">
                                                            <label className="col-xl-3 col-sm-4 mb-0">Image Path :</label>
                                                            <div className="col-xl-8 col-sm-7">
                                                                <AvField className="form-control mb-0" name="imgPath"
                                                                         type="text" required value={this.state.imagePath}
                                                                         onChange={(event) => this.changeField(event, 'imagePath')}/>
                                                            </div>
                                                            <div className="valid-feedback">Looks good!</div>
                                                        </div>
                                                        <div className="form-group mb-3 row">
                                                            <label className="col-xl-3 col-sm-4 mb-0">ViewBox :</label>
                                                            <div className="col-xl-8 col-sm-7">
                                                                <AvField className="form-control mb-0" name="viewBox"
                                                                         type="text" required value={this.state.viewBox}
                                                                         onChange={(event) => this.changeField(event, 'viewBox')}/>
                                                            </div>
                                                            <div className="valid-feedback">Looks good!</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group mb-3 row">
                                                    <label className="col-xl-3 col-sm-4 mb-0">Enabled:</label>
                                                    <div className="col-xl-8 col-sm-7">
                                                        <Switch onChange={this.changeIsEnabled}
                                                                checked={this.state.isEnabled}/>
                                                    </div>
                                                    <div className="valid-feedback">Looks good!</div>
                                                </div>
                                                <div className="form-group mb-3 row">
                                                    <label className="col-xl-3 col-sm-4 mb-0">Deleted:</label>
                                                    <div className="col-xl-8 col-sm-7">
                                                        <Switch onChange={this.changeIsDeleted}
                                                                checked={this.state.isDeleted}/>
                                                    </div>
                                                    <div className="valid-feedback">Looks good!</div>
                                                </div>
                                                <div className="form-group mb-3 row">
                                                    <label className="col-xl-3 col-sm-4 mb-0">Building Branches:</label>
                                                    <div className="form" style={{paddingRight: '9%'}}>
                                                        {
                                                            this.state.buildings && this.state.buildings.map((building, i) => {
                                                                return (
                                                                    <div key={i} style={{
                                                                        border: '1px solid black',
                                                                        padding: '15px'
                                                                    }}>
                                                                        <div className="form-group row">
                                                                            <label
                                                                                className="col-xl-3 col-sm-4 mb-0">Name:</label>
                                                                            <div className="col-xl-8 col-sm-7">
                                                                                <AvField className="form-control mb-0"
                                                                                         name="size"
                                                                                         type="text"
                                                                                         value={building.buildingName}
                                                                                         required
                                                                                         onChange={(event) => this.changeBuildingName(event, i)}/>
                                                                            </div>
                                                                            <div className="valid-feedback">Looks good!
                                                                            </div>
                                                                        </div>
                                                                        <div className="form-group row">
                                                                            <button className="btn" type="button"
                                                                                    onClick={() => this.deleteBuilding(i)}
                                                                                    style={{
                                                                                        marginLeft: '20%',
                                                                                        backgroundColor: 'transparent'
                                                                                    }}>
                                                                                <i className="fa fa-trash" style={{
                                                                                    width: 55,
                                                                                    fontSize: 35,
                                                                                    color: '#e4566e'
                                                                                }}
                                                                                />
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            })
                                                        }
                                                        <div className="form-group row offset-xl-3 offset-sm-4"
                                                             style={{marginTop: '10px'}}>
                                                            <button type="submit" className="btn" onClick={this.addNewBuilding}
                                                                    style={{
                                                                        backgroundColor: '#8DC63F',
                                                                        borderColor: '#8DC63F',
                                                                        color: 'white'
                                                                    }}>
                                                                Add new Building
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>


                                                <div className="offset-xl-3 offset-sm-4">
                                                    <button type="submit" className="btn btn-primary"
                                                            onClick={this.handleSaveClick.bind(this)}>Save
                                                    </button>
                                                    <Link to="/products/product-list">
                                                        <button type="button" className="btn btn-light">Cancel</button>
                                                    </Link>
                                                </div>
                                            </AvForm>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer/>
            </Fragment>
        )
    }

    changeCategory(event) {
        const {categories} = this.state;
        let subCategory = '';
        categories.map(category => {
            if (CategoryMap[category.category] === event.target.value) {
                subCategory = category.subCategories && category.subCategories.length !== 0 ? category.subCategories[0] : '';
            }
        });
        this.setState({
            selectedCategory: event.target.value,
            selectedSubCategory: subCategory,
        })
    }
}

export default Add_product

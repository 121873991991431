import React, {Component, Fragment} from 'react'
import Breadcrumb from '../../common/breadcrumb';
import {Edit} from 'react-feather'
import {getAllProducts, getBuildingInfos} from "../../../services/api";

import {CategoryMap, Locations, SubCategoryMap} from '../../../config';


export class Product_list extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: []
        }
    }

    componentDidMount() {
        getBuildingInfos()
            .then(data => {
              data.reverse();
                this.setState({
                    data: data
                });
            });
    }

    render() {
        return (
            <Fragment>
                <Breadcrumb title="Buildings" parent="Physical"/>
                <div className="container-fluid">
                    <div className="row products-admin ratio_asos">
                        {
                            this.state.data.map((myData, i) => {
                                return (
                                    <div className="col-xl-2 col-sm-3" key={i}>
                                        <div className="card">
                                            <div className="products-admin">
                                                <div className="card-body product-box">
                                                    <div className="img-wrapper">
                                                        Coming Soon
                                                        <div className="front">

                                                            {/*<a href={`/building/edit?buildingId=${myData.id}`}>*/}
                                                                <button className="btn" type="button">
                                                                    <Edit className="editBtn"/>
                                                                </button>
                                                            {/*</a>*/}
                                                        </div>
                                                    </div>
                                                    <div className="product-detail"
                                                         style={{marginTop: '10px', cursor: 'pointer'}}>
                                                        <h6>Name: {myData.name}</h6>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })
                        }

                    </div>
                </div>
            </Fragment>
        )
    }
}

export default Product_list

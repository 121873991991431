import React, {Component, Fragment} from 'react';
import Breadcrumb from '../../common/breadcrumb';
import {AvField, AvForm} from 'availity-reactstrap-validation';

import {getBuildingNameByFloorId, getFloorById, editApartment} from '../../../services/api'

import {ToastContainer} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {Link} from "react-router-dom";
import * as qs from "query-string";


export class AddFloor extends Component {

    constructor(props) {
        super(props);
        this.state = {
            data: {},
            apartments: [],
            floorNumber: '',
        }
    }

    componentWillReceiveProps(nextProps, nextContext) {
        const {location: {search}} = nextProps;
        const queries = qs.parse(search, {ignoreQueryPrefix: true});
        const floorId = queries.floorId;
        if (floorId) {
            getFloorById(floorId)
                .then(floor => {
                        this.setState({
                            data: floor,
                            apartments: floor.apartments,
                            id: floor.id,
                            floorNumber: floor.floorNumber
                        })
                    }
                );
            getBuildingNameByFloorId(floorId)
                .then(data => {
                        this.setState({
                            buildingName: data,
                        })
                    }
                );
        }
    }

    componentDidMount() {
        const {location: {search}} = this.props;
        const queries = qs.parse(search, {ignoreQueryPrefix: true});
        const floorId = queries.floorId;
        if (floorId) {
            getFloorById(floorId)
                .then(floor => {
                        this.setState({
                            data: floor,
                            apartments: floor.apartments,
                            id: floor.id,
                            floorNumber: floor.floorNumber
                        })
                    }
                );
            getBuildingNameByFloorId(floorId)
                .then(data => {
                        this.setState({
                            buildingName: data,
                        })
                    }
                );
        }
    }

    handleSaveClickForApartment = (item) => {
        editApartment(item)
            .then(data => {
                    alert(`Successfully saved. ApartmentId: ${data.id}`)
                }
            );
    };
    handleSaveClick() {
/*
        const priceMap = {
            "ARMENIA": this.state.priceAMD,
            "EUROPE": this.state.priceEUROPE,
            "RUSSIA": this.state.priceRUSSIA,
            "OTHER": this.state.price,
        };
        const saleMap = {
            "ARMENIA": this.state.salePriceAMD,
            "EUROPE": this.state.salePriceEUROPE,
            "RUSSIA": this.state.salePriceRUSSIA,
            "OTHER": this.state.salePrice,
        };
        const product = {
            id: this.state.id,
            name: this.state.productName,
            priceMap: priceMap,
            saleMap: saleMap,
            weightInGrams: this.state.weightInGrams,
            pictures: this.state.images,
            isNew: this.state.isNew,
            isSale: this.state.isSale,
            isEnabled: this.state.isEnabled,
            isDeleted: this.state.isDeleted,
            category: CategoryReverseMap[this.state.selectedCategory],
            subCategory: this.state.selectedSubCategory && this.state.selectedCategory === 'Men' ? MenSubCategoryReverseMap[this.state.selectedSubCategory]
                : this.state.selectedCategory === 'Kids' ? KidsSubCategoryReverseMap[this.state.selectedSubCategory] : WomenSubCategoryReverseMap[this.state.selectedSubCategory],
            sizeAndCountList: this.state.sizeAndCountMap,
            description: this.state.description,
            details: this.state.details
        };

        saveProduct(product)
            .then(data => {
                this.setState({
                    id: data.id
                });
                toast.success("Successfully Saved!");
            })
            .catch((err) => {
                toast.error("Can't Save now!")
            });*/
    }

    changeField = (event, name) => {
        this.setState({
            [name]: event.target.value
        });
    };

    changeApartmentBooleanField = (event, name, index) => {
        let apartments = this.state.apartments;
        console.log(event.target.value);
        apartments[index][name] = !apartments[index][name];
        this.setState({
            apartments
        });
    }
    changeApartmentField = (event, name, index) => {
        let apartments = this.state.apartments;
        console.log(event.target.value);
        apartments[index][name] = event.target.value;
        this.setState({
            apartments
        });
    };
    changeBuildingName = (event, index) => {
        const buildings = this.state.buildings;
        buildings[index].name = event.target.value;
        this.setState(
            buildings
        );
    };
    addNewBuilding = () => {
        const buildings = this.state.buildings;
        const building = {
            buildingName: 'new Building'
        };
        buildings.push(building);
        this.setState(
            buildings
        );
    };
    deleteBuilding = (index) => {
        const buildings = this.state.buildings;
        buildings.splice(index, 1);
        this.setState(
            buildings
        );
    };
    changeIsNew = () => {
        this.setState({
            isNew: !this.state.isNew
        });
    };
    changeIsSale = () => {
        this.setState({
            isSale: !this.state.isSale
        });
    };
    changeIsEnabled = () => {
        this.setState({
            isEnabled: !this.state.isEnabled
        });
    };
    changeIsDeleted = () => {
        this.setState({
            isDeleted: !this.state.isDeleted
        });
    };

    deleteImage(index) {
        const {images} = this.state;
        images[index] = null;
        this.setState({
            images
        });
    }

    shiftImage(count, index) {
        const {images} = this.state;
        this.array_move(images, index, index + count);
        this.setState({
            images
        })
    }

    array_move(arr, old_index, new_index) {
        if (new_index >= arr.length) {
            var k = new_index - arr.length + 1;
            while (k--) {
                arr.push(undefined);
            }
        }
        arr.splice(new_index, 0, arr.splice(old_index, 1)[0]);
        return arr; // for testing
    };

    render() {
        return (
            <Fragment>
                <Breadcrumb title="Add / Edit Building" parent="Physical"/>

                <div className="container-fluid">
                    <div className="row">
                        <div className="col-sm-12">
                            <div className="card">
                                <div className="card-header">
                                    <h5>Add / Edit Building</h5>
                                </div>
                                <div className="card-body">
                                    <div className="row product-adding">
                                        <div className="col-xl-7">
                                            <AvForm className="needs-validation add-product-form"
                                                    onValidSubmit={this.handleValidSubmit}
                                                    onInvalidSubmit={this.handleInvalidSubmit}>
                                                <div className="form form-label-center">
                                                    <div>
                                                        <div className="form-group mb-3 row">
                                                            <label className="col-xl-3 col-sm-4 mb-0">Id :</label>
                                                            <div className="col-xl-8 col-sm-7">
                                                                <AvField className="form-control mb-0" name="id"
                                                                         type="text" required value={this.state.id} disabled
                                                                         onChange={(event) => this.changeField(event, 'id')}/>
                                                            </div>
                                                            <div className="valid-feedback">Looks good!</div>
                                                        </div>
                                                        <div className="form-group mb-3 row">
                                                            <label className="col-xl-3 col-sm-4 mb-0">Building Name</label>
                                                            <div className="col-xl-8 col-sm-7">
                                                                <AvField className="form-control mb-0" name="buildingName"
                                                                         type="text" value={this.state.buildingName} disabled
                                                                         onChange={(event) => this.changeField(event, 'buildingName')}/>
                                                            </div>
                                                            <div className="valid-feedback">Looks good!</div>
                                                        </div>
                                                        <div className="form-group mb-3 row">
                                                            <label className="col-xl-3 col-sm-4 mb-0">Floor Number</label>
                                                            <div className="col-xl-8 col-sm-7">
                                                                <AvField className="form-control mb-0" name="floorNumber"
                                                                         type="number" value={this.state.floorNumber} disabled
                                                                         onChange={(event) => this.changeField(event, 'floorNumber')}/>
                                                            </div>
                                                            <div className="valid-feedback">Looks good!</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {
                                                    this.state.apartments.map((item, i) => {
                                                        return (
                                                            <div style={{border: '1px solid black', padding: '5%'}}>
                                                                <div className="form form-label-center">
                                                                    <div>
                                                                        <div className="form-group mb-3 row">
                                                                            <label className="col-xl-3 col-sm-4 mb-0">Apartment Id :</label>
                                                                            <div className="col-xl-8 col-sm-7">
                                                                                <AvField className="form-control mb-0" name="id"
                                                                                         type="text" required value={item.id} disabled
                                                                                         onChange={(event) => this.changeApartmentField(event, 'id')}/>
                                                                            </div>
                                                                            <div className="valid-feedback">Looks good!</div>
                                                                        </div>
                                                                        <div className="form-group mb-3 row">
                                                                            <label className="col-xl-3 col-sm-4 mb-0">Apartment Square</label>
                                                                            <div className="col-xl-8 col-sm-7">
                                                                                <AvField className="form-control mb-0" name="roomSquare"
                                                                                         type="text" value={item.roomSquare}
                                                                                         onChange={(event) => this.changeApartmentField(event, 'roomSquare', i)}/>
                                                                            </div>
                                                                            <div className="valid-feedback">Looks good!</div>
                                                                        </div>
                                                                        <div className="form-group mb-3 row">
                                                                            <label className="col-xl-3 col-sm-4 mb-0">Is Sold</label>
                                                                            <div className="col-xl-8 col-sm-7">
                                                                                <AvField className="form-control mb-0" name="sold"
                                                                                         type="checkbox" value={item.sold}
                                                                                         onChange={(event) => this.changeApartmentBooleanField(event, 'sold', i)}/>
                                                                            </div>
                                                                            <div className="valid-feedback">Looks good!</div>
                                                                        </div>
                                                                        <div className="form-group mb-3 row">
                                                                            <label className="col-xl-3 col-sm-4 mb-0">Is Booked</label>
                                                                            <div className="col-xl-8 col-sm-7">
                                                                                <AvField className="form-control mb-0" name="booked"
                                                                                         type="checkbox" value={item.booked}
                                                                                         onChange={(event) => this.changeApartmentBooleanField(event, 'booked', i)}/>
                                                                            </div>
                                                                            <div className="valid-feedback">Looks good!</div>
                                                                        </div>
                                                                        <div className="form-group mb-3 row">
                                                                            <label className="col-xl-3 col-sm-4 mb-0">Room Count</label>
                                                                            <div className="col-xl-8 col-sm-7">
                                                                                <AvField className="form-control mb-0" name="roomCount"
                                                                                         type="number" value={item.roomCount}
                                                                                         onChange={(event) => this.changeApartmentField(event, 'roomCount', i)}/>
                                                                            </div>
                                                                            <div className="valid-feedback">Looks good!</div>
                                                                        </div>
                                                                        <div className="form-group mb-3 row">
                                                                            <label className="col-xl-3 col-sm-4 mb-0">Price</label>
                                                                            <div className="col-xl-8 col-sm-7">
                                                                                <AvField className="form-control mb-0" name="price"
                                                                                         type="text" value={item.price}
                                                                                         onChange={(event) => this.changeApartmentField(event, 'price', i)}/>
                                                                            </div>
                                                                            <div className="valid-feedback">Looks good!</div>
                                                                        </div>
                                                                        <div className="offset-xl-3 offset-sm-4">
                                                                            <button type="submit" className="btn btn-primary"
                                                                                    onClick={(event)=> this.handleSaveClickForApartment(item)}>Save
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        );
                                                    })
                                                }


                                                <div className="offset-xl-3 offset-sm-4">
                                                    <button type="submit" className="btn btn-primary"
                                                            onClick={this.handleSaveClick.bind(this)}>Save
                                                    </button>
                                                    <Link to="/floors">
                                                        <button type="button" className="btn btn-light">Cancel</button>
                                                    </Link>
                                                </div>
                                            </AvForm>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <ToastContainer/>
            </Fragment>
        )
    }

}

export default AddFloor

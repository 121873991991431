export const config = {
    BACKEND_HOST: "https://backend.cpr.am",
    // BACKEND_HOST: "http://localhost:8088",
    FILE_UPLOAD_HOST: "https://fs.lightaffect.am",
    // FILE_UPLOAD_HOST: "http://localhost:8090",
    jwtAuthToken: 'jwt-auth-token',
};
export const Locations =
    {
        ARMENIA: 'ARMENIA',
        RUSSIA: 'RUSSIA',
        EUROPE: 'EUROPE',
        OTHER: 'OTHER'
    };
export const CategoryMap = {
    'MEN': 'Men',
    'WOMEN': 'Women',
    'KIDS': 'Kids',
    'ACCESSORIES': 'Accessories',
};
export const CategoryReverseMap = {
    'Men': 'MEN',
    'Women': 'WOMEN',
    'Kids': 'KIDS',
    'Accessories': 'ACCESSORIES',
};
export const SubCategoryMap = {
    'MEN_T_SHIRTS': 't-shirts',
    'MEN_PANTS': 'pants',
    'MEN_JACKETS': 'jackets',
    'MEN_SHIRTS': 'shirts',
    'MEN_HOODIES': 'hoodies',
    'MEN_SWEATSHIRTS': 'sweatshirts',
    'MEN_COATS': 'coats',
    'WOMEN_T_SHIRTS': 't-shirts',
    'WOMEN_SWEATSHIRTS': 'sweatshirts',
    'WOMEN_SHIRTS_AND_PANTS': 'skirts-and-pants',
    'WOMEN_HOODIES': 'hoodies',
    'WOMEN_DRESS': 'dresses',
    'WOMEN_JACKETS': 'jackets',
    'WOMEN_TRACKSUITS': 'tracksuits',
    'KIDS_DRESS': 'dress',
    'KIDS_HOODIE': 'hoodie',
    'KIDS_BODY_SUITS': 'body-suits',
    'KIDS_SWEATSHIRTS': 'sweatshirts',
    'KIDS_TROUSERS': 'trousers',
    'KIDS_HATS': 'hats',
    'KIDS_JACKETS': 'jackets',
    'KIDS_T_SHIRTS': 't-shirts',
    'KIDS_SKIRTS': 'skirts',
};
export const MenSubCategoryReverseMap = {
    't-shirts': 'MEN_T_SHIRTS',
    'pants': 'MEN_PANTS',
    'jackets': 'MEN_JACKETS',
    'shirts': 'MEN_SHIRTS',
    'hoodies': 'MEN_HOODIES',
    'sweatshirts': 'MEN_SWEATSHIRTS',
    'coats': 'MEN_COATS',
};
export const WomenSubCategoryReverseMap = {
    't-shirts': 'WOMEN_T_SHIRTS',
    'skirts-and-pants': 'WOMEN_SHIRTS_AND_PANTS',
    'hoodies': 'WOMEN_HOODIES',
    'jackets': 'WOMEN_JACKETS',
    'dresses': 'WOMEN_DRESS',
    'sweatshirts': 'WOMEN_SWEATSHIRTS',
    'tracksuits': 'WOMEN_TRACKSUITS',
};

export const KidsSubCategoryReverseMap = {
    'dress': 'KIDS_DRESS',
    'hoodie': 'KIDS_HOODIE',
    'body-suits': 'KIDS_BODY_SUITS',
    'sweatshirts': 'KIDS_SWEATSHIRTS',
    'trousers': 'KIDS_TROUSERS',
    'hats': 'KIDS_HATS',
    'jackets': 'KIDS_JACKETS',
    't-shirts': 'KIDS_T_SHIRTS',
    'skirts': 'KIDS_SKIRTS',
};
